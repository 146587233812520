import './index.scss';
import { ReactElement } from 'react';
import Routing from 'app/providers/routing';
import useInterceptors from './providers/axiosIntersectors';

const App = (): ReactElement => {
  useInterceptors();
  return <Routing />;
};

export default App;
