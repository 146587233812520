import { combineReducers, configureStore } from '@reduxjs/toolkit';
import registrationsReducer from 'app/store/registration/registrations.slice';

const rootReducer = combineReducers({
  registrationState: registrationsReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store.getState>;
export type AppDispatch = (typeof store)['dispatch'];
