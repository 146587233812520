import { AppService, AppStatus, UsageVariant } from './types';

export const StorageData = 'reg-help-user';

//
// Applications
//

export const appServicesMap: Record<AppService, string> = {
  REGISTRATION_OF_PLOT_OWNERSHIP: 'Оформление права собственности на земельный участок',
  REGISTRATION_OF_OKS_OWNERSHIP: 'Оформление права собственности на объект капитального стр-ва',
  LAND_SURVEYING: 'Межевание участка',
  PLOT_CADASTRAL_REGISTRATION: 'Постановка на кадастровый учёт земельного участка',
  OKS_CADASTRAL_REGISTRATION: 'Постановка на кадастровый учёт объекта капитального стр-ва',
  LEASE_AGREEMENT: 'Оформление договора аренды на земельный участок',
};

export const appUsageVariantsMap: Record<UsageVariant, string> = {
  FOR_PERSONAL_FARMING: 'Для ведения личного подсобного хозяйства',
  FOR_GARDENING: 'Для ведения гражданами садоводства и огородничества',
  FOR_COUNTRY_HOUSE_CONSTRUCTION: 'Для дачного строительства',
  FOR_INDIVIDUAL_RESIDENTIAL_DEVELOPMENT: 'Для индивидуальной жилой застройки',
  FOR_MULTIFAMILY_DEVELOPMENT: 'Для многоквартирной застройки',
  FOR_PRIMARY_AND_SECONDARY_EDUCATION:
    'Для размещения объектов дошкольного, начального, общего и среднего (полного) общего образования',
  FOR_HIGHER_DEGREE_EDUCATION:
    'Для размещения объектов среднего профессионального и высшего профессионального образования',
  FOR_RELIGIOUS_BUILDINGS: 'Для размещения культовых зданий',
  FOR_HOTEL_ACCOMMODATION: 'Для размещения гостиниц',
  FOR_INDIVIDUAL_GARAGES: 'Для размещения индивидуальных гаражей',
  FOR_CULTURAL_OBJECTS: 'Для размещения объектов культуры',
  FOR_COMMERCIAL_OBJECTS: 'Для размещения объектов торговли',
  FOR_CATERING_FACILITIES: 'Для размещения объектов общественного питания',
  FOR_BUSINESS_OBJECTS: 'Для размещения объектов предпринимательской деятельности',
  FOR_ADMINISTRATIVE_BUILDINGS: 'Для размещения административных зданий',
  FOR_COMMUNAL_SERVICE_FACILITIES: 'Для размещения объектов жилищно-коммунального хозяйства',
  FOR_INDUSTRIAL_FACILITIES: 'Для размещения промышленных объектов',
  FOR_RECREATIONAL_FACILITIES: 'Для размещения домов отдыха, пансионатов, кемпингов',
  FOR_SPORT_FACILITIES: 'Для размещения объектов физической культуры и спорта',
  OTHER: 'Другое',
};

export const appStatusesMap: Record<AppStatus, string> = {
  CREATED: 'Успешно создана',
  FILLED: 'Заявка на обработке, и не может быть обновлена',
  WAIT_FOR_CLIENT_RESPONSE: 'Укажите дополнительную информацию',
  SENT_TO_THE_CADASTRAL_ENGINEER: 'Заявка направлена кадастровому инженеру',
  SENT_TO_THE_ADMINISTRATION: 'Заявка направлена в мэрию',
  DOCS_ARE_READY_TO_BE_TRANSFERED: 'Комплект документов готов для передачи в МФЦ',
  DOCS_ARE_TRANSFERED_TO_MFC: 'Комплект документов передан в МФЦ',
  COMPLETED: 'Заявка завершена',
  REJECTED: 'Заявка отклонена',
};
