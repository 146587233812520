import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { StorageData } from 'shared/config/constants';
import AuthContext from 'widgets/auth/model/context';
import { Nullable } from 'shared/config/types';

const WithAuth: React.FC<PropsWithChildren> = ({ children }): React.ReactElement => {
  const [phone, setPhone] = useState<Nullable<string>>(null);
  const [init, setInit] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const login = (data: string): void => {
    setPhone(data);
  };

  const logout = (): void => {
    setPhone(null);
    localStorage.removeItem(StorageData);
    if (pathname !== '/login') {
      navigate('/login', { state: pathname });
    }
  };

  const value = useMemo(
    () => ({
      phone,
      init,
      login,
      logout,
    }),
    [phone, init],
  );

  useEffect(() => {
    const regUser = localStorage.getItem(StorageData);
    if (regUser) {
      login(JSON.parse(regUser).phone);
    } else {
      logout();
    }
    setInit(true);
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default WithAuth;
