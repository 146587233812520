import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { loginedRoutes, notLoginedRoutes } from 'app/providers/routing/routes';
import { useAuth } from 'widgets/auth/model/context';
import { Spin } from 'antd';

const Routing = (): ReactElement => {
  const { phone, init } = useAuth();
  if (!init) {
    return <Spin />;
  }

  return (
    <Routes>
      {(phone ? loginedRoutes : notLoginedRoutes).map((route) => (
        <Route key={route.key} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default Routing;
