declare global {
  interface Window {
    env: object;
  }
}

type EnvType = {
  REACT_APP_API_URL: string;
};

const envVars = { ...process.env, ...window.env } as EnvType;
export default envVars;
