import { lazy } from 'react';
import { RoutesLogin } from 'app/providers/auth/types';

const HomePage = lazy(() => import('pages/homePage'));
const StepperFormPage = lazy(() => import('pages/stepperFormPage'));
const NotFound = lazy(() => import('pages/notFoundPage'));
const Login = lazy(() => import('pages/loginPage'));
const List = lazy(() => import('pages/appListPage'));

const notLoginedRoutes: RoutesLogin[] = [
  {
    key: 'homePage',
    path: '/',
    element: <HomePage />,
  },
  {
    key: 'login',
    path: '/login',
    element: <Login />,
  },
  {
    key: 'notFound',
    path: '*',
    element: <NotFound />,
  },
];

const loginedRoutes: RoutesLogin[] = [
  ...notLoginedRoutes,
  {
    key: 'form',
    path: '/form',
    element: <StepperFormPage />,
  },
  {
    key: 'list',
    path: '/list',
    element: <List />,
  },
];

export { loginedRoutes, notLoginedRoutes };
