import { InternalAxiosRequestConfig } from 'axios';
import { StorageData } from 'shared/config/constants';
import $api from 'shared/api';
import { useAuth } from 'widgets/auth/model/context';

const useInterceptors = (): void => {
  const { logout } = useAuth();
  $api.interceptors.request.use(
    (value) => {
      if (value.url?.includes('requestOtp')) {
        return value;
      }
      const jwt = JSON.parse(localStorage.getItem(StorageData) as string)?.jwt;
      return {
        ...value,
        headers: {
          ...value.headers,
          Authorization: `Bearer ${jwt}`,
        },
      } as InternalAxiosRequestConfig;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  $api.interceptors.response.use(
    (value) => value,
    (error) => {
      if (error.response.status === 401 && logout) {
        logout();
      }
      return Promise.reject(error);
    },
  );
};
export default useInterceptors;
