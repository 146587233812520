import axios, { AxiosResponse } from 'axios';
import envVars from 'app/env/env';

export interface QueryInputType<T> {
  urlParams?: string[];
  requestParams?: T;
}

export enum RequestType {
  Post = 'POST',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

const $api = axios.create({
  baseURL: envVars.REACT_APP_API_URL,
});

const mutation =
  <InputType, OutputType>(requestType?: RequestType) =>
  async (
    url: string,
    { arg }: Readonly<{ arg: InputType }>,
  ): Promise<AxiosResponse<OutputType>> => {
    switch (requestType) {
      case RequestType.Delete: {
        return await $api.delete(url, { data: arg });
      }
      case RequestType.Update: {
        return await $api.put(url, arg);
      }
      case RequestType.Post:
      default: {
        return await $api.post(url, arg);
      }
    }
  };

const query =
  <T, OutputType>(input?: Readonly<QueryInputType<T>>) =>
  async (url: string): Promise<OutputType> => {
    const params = input?.requestParams ?? null;
    const urlParams = input?.urlParams?.join('/');
    const { data } = await $api.get(`${url}${urlParams ? `/${urlParams}` : ''}`, { params });
    return data;
  };

export default $api;
export { mutation, query };
