import { createContext, useContext } from 'react';
import { IAuth } from 'app/providers/auth/types';

const AuthContext = createContext<IAuth>({
  phone: null,
  init: false,
});

const useAuth = (): IAuth => useContext(AuthContext);

export default AuthContext;
export { useAuth };
