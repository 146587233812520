import React, { PropsWithChildren, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Spin } from 'antd';

const WithRouter: React.FC<PropsWithChildren> = ({ children }): React.ReactElement => (
  <BrowserRouter>
    <Suspense fallback={<Spin />}>{children}</Suspense>
  </BrowserRouter>
);
export default WithRouter;
