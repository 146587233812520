import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegistrationForm } from 'features/servicesForm/config/types';
import { Nullable } from 'shared/config/types';

interface RegistrationsState {
  id: Nullable<number>;
  form: Nullable<IRegistrationForm>;
}

const initialState: RegistrationsState = {
  form: null,
  id: null,
};

const registrationAdapter = createEntityAdapter<RegistrationsState>();

const registrationSlice = createSlice({
  name: 'registration',
  initialState: registrationAdapter.getInitialState(initialState),
  reducers: {
    saveRegistrationForm: (state, action: PayloadAction<IRegistrationForm>) => {
      state.form = action.payload;
    },
    saveWorkId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
  },
});

export const { saveRegistrationForm, saveWorkId } = registrationSlice.actions;

export default registrationSlice.reducer;
