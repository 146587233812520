import { WithAuth, WithRouter, WithStore } from 'app/providers';
import ReactDOM from 'react-dom/client';
import App from './app';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <WithStore>
    <WithRouter>
      <WithAuth>
        <App />
      </WithAuth>
    </WithRouter>
  </WithStore>,
);
